exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-details-page-js": () => import("./../../../src/templates/about-details-page.js" /* webpackChunkName: "component---src-templates-about-details-page-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-access-declaration-page-js": () => import("./../../../src/templates/access-declaration-page.js" /* webpackChunkName: "component---src-templates-access-declaration-page-js" */),
  "component---src-templates-bulletin-page-js": () => import("./../../../src/templates/bulletin-page.js" /* webpackChunkName: "component---src-templates-bulletin-page-js" */),
  "component---src-templates-class-js": () => import("./../../../src/templates/class.js" /* webpackChunkName: "component---src-templates-class-js" */),
  "component---src-templates-classes-page-js": () => import("./../../../src/templates/classes-page.js" /* webpackChunkName: "component---src-templates-classes-page-js" */),
  "component---src-templates-contact-form-page-js": () => import("./../../../src/templates/contact-form-page.js" /* webpackChunkName: "component---src-templates-contact-form-page-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-courses-page-js": () => import("./../../../src/templates/courses-page.js" /* webpackChunkName: "component---src-templates-courses-page-js" */),
  "component---src-templates-culture-page-js": () => import("./../../../src/templates/culture-page.js" /* webpackChunkName: "component---src-templates-culture-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-history-page-js": () => import("./../../../src/templates/history-page.js" /* webpackChunkName: "component---src-templates-history-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-instructor-js": () => import("./../../../src/templates/instructor.js" /* webpackChunkName: "component---src-templates-instructor-js" */),
  "component---src-templates-instructors-page-js": () => import("./../../../src/templates/instructors-page.js" /* webpackChunkName: "component---src-templates-instructors-page-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/map-page.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-prices-and-schedule-page-js": () => import("./../../../src/templates/prices-and-schedule-page.js" /* webpackChunkName: "component---src-templates-prices-and-schedule-page-js" */),
  "component---src-templates-prices-page-js": () => import("./../../../src/templates/prices-page.js" /* webpackChunkName: "component---src-templates-prices-page-js" */),
  "component---src-templates-schedule-page-js": () => import("./../../../src/templates/schedule-page.js" /* webpackChunkName: "component---src-templates-schedule-page-js" */),
  "component---src-templates-statute-page-js": () => import("./../../../src/templates/statute-page.js" /* webpackChunkName: "component---src-templates-statute-page-js" */)
}

